<template>
  <main class="Works">
    <div class="container mb-5 pt-4">
      <h2 class="text-newblue fw-bold pb-2 border-bottom border-5 border-newblue">
        Facilities
      </h2>
      <!-- sticky-top bar -->
      <div class="border-top border-3 sticky-top">
        <button
          class="btn btn-light btn-show fw-bolder mr-2"
          v-for="(year, index) in years.slice()"
          :key="year"
          :class="{ 'btn-hidden': scrollY < yearsPositionArray[index] }"
          @click="scrollToYearPosition(index)"
        >
          {{ year }}
        </button>
      </div>
      <!-- facility contents-->
      <section
        v-for="(year, index) in years"
        :ref=" (el) => { yearsRefs[index] = el; }"
        :key="index"
      >
      <div class="pt-4 pb-0">
        <h2 class="markerPen d-inline-block fw-bolder px-1 mb-4">{{ year }}</h2>
      </div>
        <article
          v-for="(eachWork, worksIndex) in worksData"
          :ref="
            (element) => {
              articleRefs[year + eachWork.id] = element;
            }
          "
          :key="worksIndex"
        >
        <div v-if="year == CheckYears(eachWork)"
         class="row g-0"
          data-aos="fade-left"
          data-aos-easing="linear"
          data-aos-duration="1000">
          <h3 class="fw-bold">{{ eachWork.name }}</h3>
          <div class="col-12 col-lg-6">
            <div v-if="eachWork.photo.length !== 1" id="carouselExampleIndicators"
                    class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-indicators">
                <button v-for="(i , index) in eachWork.photo" :key="index" type="button"
                  data-bs-target="#carouselExampleIndicators"
                  :data-bs-slide-to='index' :aria-current="index === 0 ? 'true':''"
                  :class="index === 0 ? 'active':''" :aria-label="`Slide ${index}`">
                </button>
              </div>
              <div class="carousel-inner">
                <div v-for="(i , index) in eachWork.photo" :key="index"
                class="carousel-item" data-bs-interval="10000" :class="index === 0 ? 'active':''">
                <img :src="i.photo" class="photo-fit d-block w-100">
                </div>
              </div>
              <button class="carousel-control-prev" type="button"
              data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button"
              data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
            <div v-else class="align-self-center">
                <img :src="eachWork.photo?.[0]?.photo" class="img-fluid d-block w-100" />
            </div>
          <!-- <img :src="`${eachWork.photo?.[0]?.photo}`"
          class="img-fluid" /> -->
          </div>
          <div class="col-12 col-lg-6 row g-0 align-items-center mb-2 mb-lg-0">
            <div class="col-12 offset-lg-1 py-1 pe-5">
              <p class="text-black-50">
                {{ eachWork.create_date }}
              </p>
              <p>
                {{ eachWork.description }}
              </p>
              <p v-if="eachWork.participant.length === 1"
                class="text-end pt-2">
                  參與者：{{ eachWork.participant[0].name }}
                </p>
                <p v-if="eachWork.participant.length > 1"
                class="text-end pt-2">
                  參與者：
                  <span v-for="(eachmember, index) in eachWork.participant" :key="index">
                    {{ eachmember.name }}  &nbsp;</span>
                </p>
              <p class="text-end text-black-50">
                {{ eachWork.note }}
              </p>
              <div v-if="eachWork.file.length !== 0" class="pb-3 attached facilitydoc">
                <p>檔案下載：</p>
                <a v-for="(files, i) in eachWork.file" :key=i target="_blank" :href="files.file">
                  <i class="bi bi-download pe-2"></i>
                  {{ decodeURI(files.file.slice(40, )) }}
                <br>
                </a>
              </div>
            </div>
          </div>
        </div>
      </article>
      </section>
      <!-- fixed-bottom bar -->
      <div class="fixed-bottom-bar container border-bottom border-3 mb-4 fixed-bottom">
        <button
          class="btn btn-light btn-show fw-bolder mr-2"
          v-for="(year, index) in years.slice()"
          :key="year"
          :class="{ 'btn-hidden': scrollY > yearsPositionArray[index] }"
          @click="scrollToYearPosition(index)"
        >
          {{ year }}
        </button>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive } from 'vue';
import {
  scrollPositionDetect,
  yearsPositionArrayProduce,
} from '../composition/works';
import { ideaSkyGetRequest } from '../composition/api';
import 'bootstrap-icons/font/bootstrap-icons.css';

// 存放 Refs 以獲取元素高 => 調轉到指定位置
const yearsRefs = ref([]);
const articleRefs = reactive({});

// 偵測目前位置決定sticky-top bar出現年份 & 跳轉到年份位置function
const scrollY = scrollPositionDetect().y;
const yearsPositionArray = ref([]);

// API 資料串接
// const url = 'http://127.0.0.1:8005/api/v1/ideasky/data/?mytype=f';
const url = `${process.env.VUE_APP_API_URL}data/?mytype=f`;
// const url = 'https://ideasky.app/api/v1/ideasky/data/?mytype=f';
const worksData = ref([]);
const years = ref([]);

function getAllYearsData(yearsByFetch) {
  const allRequestArray = [];
  yearsByFetch.forEach((eachProfile) => {
    const eachYear = eachProfile.create_date.slice(0, 4);
    allRequestArray.push(eachYear);
  });
  years.value = Array.from(new Set(allRequestArray))
    .sort()
    .reverse();
  // console.log(years);
  setTimeout(() => {
    yearsPositionArray.value = yearsPositionArrayProduce(yearsRefs.value).positionArray;
  }, 100);
}

ideaSkyGetRequest(url).then((res) => {
  worksData.value = res;
  getAllYearsData(res);
  // console.log(decodeURI(worksData.value[0].file.slice(32, -4)));
});

const scrollToYearPosition = (index) => {
  const top = yearsRefs.value[index].offsetTop;
  window.scrollTo(0, top - 150); // -100px 是不要讓 sticky-top bar 擋住年份
};

function CheckYears(data) {
  const WorksYear = data.create_date.slice(0, 4);
  return WorksYear;
}

</script>
<style>
.facilitydoc a:link{
  text-decoration:none;
}
.facilitydoc a:visited {
  color:gray;
}
.facilitydoc a:hover {
  text-decoration:underline;
}
.photo-fit {
    object-fit: cover;
    width: 100%;
    height: 450px;
  }
</style>
