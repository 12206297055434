<template>
    <main class="news">
        <div class="container top pt-4">
            <h2 class="text-newblue fw-bold pb-2 border-bottom border-5
            border-newblue">News</h2>
        </div>
        <Newsdetail v-if="Isdetail" :data="datadetail" @backnews="backnews" />
        <!-- 搜尋欄-->
        <div v-if="!Isdetail" class="container pt-3">
            <div class="container">
            <div class="p-2 row justify-content-between bg-gray rounded-pill">
              <form class="add-form row">
                <div class="col-2 col-lg-2 pt-2 d-flex justify-content-end">
                    <label class="form-label align-self-center">關鍵字</label>
                </div>
                <div class="col-10 col-lg-2">
                    <input type="text" class="form-control" placeholder="請輸入關鍵字"
                    id="keyword" v-model="keyword">
                </div>
                <div class="col-2 col-lg-1 pt-2 d-flex justify-content-end">
                    <label for="searchdate" class="form-label align-self-center">日期</label>
                </div>
                <div class="col-4 col-lg-2">
                    <input id="st" type="date" class="form-control" v-model="st">
                </div>
                <div class="col-2 col-lg-1 pt-2 d-flex align-self-center justify-content-center">
                    <span> ~ </span>
                </div>
                <div class="col-4 col-lg-2">
                    <input id="end" name="end" type="date" class="form-control" v-model="end">
                </div>
                <div class="col-12 col-lg-2 d-flex justify-content-lg-end">
                    <button type="button" class="btn btn-dark rounded-pill"
                    @click="onSubmit()">Search</button>
                </div>
              </form>
            </div>
            <br />
            <!-- 排版 -->
            <div v-for="news in data" :key=news class="row link newspic">
                <div class="col-12 col-md-3 d-flex align-items-center">
                    <a href="#" @click.prevent="details(news.id)" class="pb-3">
                    <div class="newshidden">
                    <img :src="news.photo?.[0]?.photo"
                    class="photo-fit" />
                    </div>
                    </a>
                </div>
                <div class="col-12 col-md-9 pb-3 newspoint">
                    <span class="text-black-50 p-0"> 發布日期： {{ news.create_date }}</span>
                    <h4 class="pb-2 pt-2"> {{ news.title }} </h4>
                    <a href="#" @click.prevent="details(news.id)">
                    {{ news.description.slice(0, wordLimit)}}<span>...(查看更多)</span>
                    </a>
                </div>
                <hr>
            </div>
        </div>
    </div>
        <div v-if="!Isdetail" class="btn-toolbar justify-content-center m-0" role="toolbar">
          <nav aria-label="Page navigation justify-content-center m-0">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Previous" @click.prevent="back(currentpage)">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li v-for="page in pages+1" :key="page" class="page-item">
            <a class="page-link" :class="[ page === currentpage ? 'active':'']"
            href="#" @click.prevent="onPageChange(page)">{{ page }}</a></li>
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Next" @click.prevent="next(currentpage)">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
        </div>
    <br>
    </main>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import { ideaSkyGetRequest } from '../composition/api';
import Newsdetail from './Newsdetail.vue';

const Isdetail = ref(false);
const datadetail = ref();
// API 資料串接
const url = `${process.env.VUE_APP_API_URL}news/?ordering=-create_date`;
const data = ref([]);
const pages = ref();
const currentpage = ref(1);
const keyword = ref('');
const st = ref('');
const end = ref('');
const wordLimit = ref(200);

const route = useRoute();
if (route.params.id) {
  const urldetail = `${process.env.VUE_APP_API_URL}news/${route.params.id}`;
  ideaSkyGetRequest(urldetail).then((res) => {
    datadetail.value = res;
    Isdetail.value = true;
  });
}

ideaSkyGetRequest(url).then((NewsData) => {
  data.value = NewsData.results;
  pages.value = Math.floor(NewsData.count / 5);
});

function onPageChange(page) {
  currentpage.value = page;
  let urlpage = `${process.env.VUE_APP_API_URL}news/?ordering=-create_date`;
  if (page !== 1) {
    urlpage = `${process.env.VUE_APP_API_URL}news/?ordering=-create_date&page=${page}`;
  }
  ideaSkyGetRequest(urlpage).then((NewsData) => {
    data.value = NewsData.results;
  });
}

function onSubmit() {
  if (keyword.value !== null || st.value !== null || end.value !== null) {
    const search = `${process.env.VUE_APP_API_URL}news/?ordering=-create_date&search=${keyword.value}&start_time=${st.value}&end_time=${end.value}`;
    ideaSkyGetRequest(search).then((res) => {
      if (res.results.length !== 0) {
        data.value = res.results;
        pages.value = Math.floor(res.count / 5);
      } else {
        alert('查無資料');
      }
    });
  } else if (keyword.value !== null) {
    const urlsearch = `${process.env.VUE_APP_API_URL}news/?ordering=-create_date&search=${keyword.value}`;
    ideaSkyGetRequest(urlsearch).then((res) => {
      if (res.results.length !== 0) {
        data.value = res.results;
        pages.value = Math.floor(res.count / 5);
      } else {
        alert('查無資料');
      }
    });
  } else if (st.value !== null || end.value !== null) {
    const urldate = `${process.env.VUE_APP_API_URL}news/?ordering=-create_date&start_time=${st.value}&end_time=${end.value}`;
    ideaSkyGetRequest(urldate).then((res) => {
      if (res.results.length !== 0) {
        data.value = res.results;
        pages.value = Math.floor(res.count / 5);
      } else {
        alert('查無資料');
      }
    });
  }
}

function backnews() {
  Isdetail.value = false;
}

function details(newsid) {
  const urldetails = `${process.env.VUE_APP_API_URL}news/${newsid}`;
  ideaSkyGetRequest(urldetails).then((res) => {
    datadetail.value = res;
    Isdetail.value = true;
  });
}

function next(page) {
  if (page !== pages.value + 1) {
    onPageChange(page + 1);
  }
}

function back(page) {
  if (page !== 1) {
    onPageChange(page - 1);
  }
}

</script>
<style scoped>
.big-icon {
    font-size: 18rem;
}
.newspoint a:visited {
  color: gray;
}
.newspoint a:hover {
  text-decoration:underline;
}
.newspoint a:link{
  text-decoration:none;
}
.newshidden {
  overflow: hidden;
}
.newspic:hover img {
  transform:scale(1.1,1.1);
}
img {
  transform:scale(1,1);
  transition: all 1s ease-out;
  }
.photo-fit {
  object-fit: cover;
  width: 100%;
  height: 200px;
}
</style>
