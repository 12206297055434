<template>
  <div id="ExampleDark" class="carousel carousel-dark slide col-12 col-md-12"
        data-bs-ride="carousel"
          data-aos-duration="500" data-aos="fade-top" data-aos-easing="linear">
    <div class="carousel-indicators">
      <button v-for="(banner, index) in data" :key="index" type="button"
      data-bs-target="#ExampleDark"
      :data-bs-slide-to="index" :class="index === 0 ? 'active':''" :aria-label="`Slide ${index+1}`">
      </button>{{ key }}
    </div>
    <div class="carousel-inner">
      <div v-for="(banner, i) in data" :key="i" class="carousel-item"
      :class="i === 0 ? 'active':''" data-bs-interval="2000">
        <img :src="banner.photo" class="d-block w-100" style="height: calc(100vh)">
        <div class="carousel-caption d-none d-md-block bannerword text-start">
          <p :style="{ 'max-width': '60vw' }">{{ banner.description }}</p>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#ExampleDark"
      data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#ExampleDark"
      data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import AOS from 'aos';
import { ideaSkyGetRequest } from '../../composition/api';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

AOS.init();
const url = `${process.env.VUE_APP_API_URL}index/`;
const data = ref([]);

ideaSkyGetRequest(url).then((indexData) => {
  data.value = indexData;
  // console.log(data.value);
});
</script>

<style scoped>
#ExampleDark .carousel-inner {
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
}

.bannerword {
  top: 35%;
  left: 10%;
}
</style>
