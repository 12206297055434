<template>
<main class="member">
    <div class="container top pt-4">
      <h2 class="text-newblue fw-bold pb-2 border-bottom border-5 border-newblue">Member</h2>
    </div>
    <div class="container">
      <div class="row align-items-start">
        <div class="col-12 col-md-3 mx-auto">
          <!-- <form class="d-flex py-3 position-relative">
            <input
              class="rounded-pill form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button
              class=" rounded-pill position-absolute end-0 btn btn-outline-success"
              type="submit"
              style="right:0px"
            >
              Search
            </button>
          </form> -->
          <div class="collapse d-grid gap-2 bg-faded pt-2" id="sidebar">
            <ul>
                <li class="col">
                  <a class="btn border collapsed col d-block text-start" href="#lead" role="button"
                  data-bs-toggle="collapse" data-bs-target="#lead">Leadership</a>
                  <div class="cpllapse border px-3" id="lead" aria-expanded="false">
                      <ul class="flex-column p-2">
                        <li class="col"><a href="#" @click.prevent="leader()">卷積雲（老師）</a></li>
                        <li class="col"><a href="#" @click.prevent="committ()">高積雲（幹部）</a></li>
                      </ul>
                    </div>
                </li>
                <li class="col">
                    <a class="btn border collapsed col d-block text-start" href="#stu" role="button"
                    data-bs-toggle="collapse" data-bs-target="#stu" @click="student()">
                    層積雲（學生)</a>
                    <div class="collapse border px-3" id="stu" aria-expanded="false">
                        <ul class="flex-column p-2">
                          <li v-for="each in years" :key="each">
                            <a class="mw-100" :href="'#' + each">{{ each }}</a>
                          </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        </div>
        <div class="container col-12 col-md-9">
          <section v-for="year in years" :key="year" :id="year">
            <h2 v-if="leaders" class="markerPen d-inline-block fw-bolder px-1 mb-4">
              Leadership
            </h2>
            <h2 v-else class="markerPen d-inline-block fw-bolder px-1 mb-4">
              Members Joined in {{ year }}
            </h2>
            <div class="row gx-3">
              <div
                class="row gx-3 col-md-6 col-lg-4 p-3 position-relative"
                style="border-radius: 3% 3% 0% 0%"
                v-for="eachMember in members[year]"
                :key="eachMember"
              >
                <div class="card-body">
                  <a target="_blank" :href="eachMember.website">
                  <div class="p-0 m-0">
                  <img :src="eachMember.photo?.[0]?.photo"
                  class="photo-fit" />
                  </div>
                  <h5 class="card-title fw-bolder pb-1 pt-2">
                    {{ eachMember.name }} {{ eachMember.position }}
                  </h5>
                  <h4>
                    {{ eachMember.department }}
                  </h4>
                  <p class="card-text">
                    成就：{{ eachMember.description }}
                    <br />
                    <span class="fw-bolder text-muted">
                      Joined on : {{ eachMember.create_date }}</span>
                  </p>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref } from 'vue';
import { ideaSkyGetRequest } from '../composition/api';

// API 資料串接
const aa = process.env.VUE_APP_API_FRONT_URL;
const url = `${process.env.VUE_APP_API_URL}profile/?level=s`;
const leaders = ref(false);
const members = ref({});
const years = ref([]);

const statusMap = {
  c: 'Current',
  l: 'Former',
};
const getData = async (Profile) => {
  members.value = [];
  Profile.forEach((eachProfile) => {
    const eachYear = eachProfile.create_date.slice(0, 4);
    const tempProfile = { ...eachProfile };
    tempProfile.status = statusMap[eachProfile.status];
    if (members.value[eachYear] === undefined) {
      members.value[eachYear] = [];
    }
    members.value[eachYear].push(tempProfile);
  });
  years.value = Array.from(Object.keys(members.value))
    .sort()
    .reverse();
};

ideaSkyGetRequest(url).then((Data) => {
  getData(Data);
});

function leader() {
  const leaderurl = `${process.env.VUE_APP_API_URL}profile/?level=l`;
  leaders.value = true;
  ideaSkyGetRequest(leaderurl).then((Data) => {
    getData(Data);
  });
}

function committ() {
  const committurl = `${process.env.VUE_APP_API_URL}profile/?level=c`;
  leaders.value = false;
  ideaSkyGetRequest(committurl).then((Data) => {
    getData(Data);
  });
}

function student() {
  const committurl = `${process.env.VUE_APP_API_URL}profile/?level=s`;
  leaders.value = false;
  ideaSkyGetRequest(committurl).then((Data) => {
    getData(Data);
  });
}

</script>
