<template>
  <main class="Studentswork" :style="{'background': '#F5F5F5'}">
      <div class="container top">
          <h2 class="bg-newblue text-center text-white title d-inline-block rounded-pill p-2"
        :style="{ 'min-width': '10rem' }">學生作品</h2>
      </div>
      <br>
          <div class="container">
          <!-- 排版 -->
          <div v-for="work in data.slice(0,3)" :key=work class="row link bg-gray workspoint works">
              <div class="col-12 col-md-5 col-lg-3 d-flex align-content-end">
                <div class="pb-3">
                <div class="workspic">
                  <img :src="work.photo?.[0]?.photo" class="photo-fit" />
                </div>
              </div>
              </div>
              <div class="col-12 col-md-7 col-lg-9 pb-3 pt-2" @click.capture="details(work.id)">
                  <span class="text-black-50 p-0">{{ work.create_date }}</span>
                  <h4 class="pb-2 pt-2"> {{ work.name }} </h4>
                  <a id="works" class="nav-link" :href="`/works/${change(work)}`">
                  {{ `${work.description.slice(0, wordLimit)}....`
                  }}<span class="fw-light text-muted">Read more </span>
                  </a>
              </div>
              <hr>
          </div>
      </div>
  <br>
  </main>
</template>

<script setup>
import { ref } from 'vue';
import { ideaSkyGetRequest } from '../../composition/api';

const wordLimit = ref(200);

// API 資料串接
const url = `${process.env.VUE_APP_API_URL}data/?mytype=w&is_pinned=true&ordering=-create_date`;
const data = ref([]);
const id = ref();

ideaSkyGetRequest(url).then((WorkData) => {
  data.value = WorkData;
});

function details(workid) {
  id.value = workid;
}

function change(work) {
  const workscroll = work.create_date.slice(0, 4) + work.id;
  return workscroll;
}

</script>
<style scoped>
.works a:link{
  text-decoration:none;
}
.works a:visited {
  color:gray;
}
.works a:hover {
  text-decoration:underline;
}
.workspic {
  overflow: hidden;
}
.workspoint:hover img {
  transform:scale(1.1,1.1);
}
img {
  transform:scale(1,1);
  transition: all 1s ease-out;
}
.photo-fit {
    object-fit: cover;
    width: 100%;
    height: 200px;
  }
</style>
