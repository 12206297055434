import { ref, onMounted, onUnmounted } from 'vue';

export function scrollPositionDetect() {
  const y = ref(0);

  const update = () => {
    y.value = window.top.scrollY;
  };
  onMounted(() => {
    window.addEventListener('scroll', update);
  });
  onUnmounted(() => {
    window.removeEventListener('scroll', update);
  });
  return { y }; // this.scrollY == y
}

export function cardFlip(currentCardId, currentActive, id) {
  let newID = null;
  let newActive = false;

  if (currentCardId !== id) {
    newID = id;
    newActive = true;
    return { newID, newActive };
  }
  newActive = !currentActive;
  newID = null;
  return { newID, newActive };
}

export function btnCloseCard() {
  const newID = null;
  const newActive = false;
  return { newID, newActive };
}

export function yearsPositionArrayProduce(yearsRefs) {
  const positionArray = [];
  let totalHeight = 0;
  for (let i = 0; i < yearsRefs.length; i += 1) {
    positionArray.push(totalHeight);
    totalHeight += yearsRefs[i].clientHeight;
  }
  return { positionArray };
}
