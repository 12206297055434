<template>
  <!-- TODO: fixed -->
  <nav id="box"
    class="navbar navbar-expand-lg navbar-light sticky-top bg-newblue"
    :class="[collapseIsActive === btn ? '' : 'opacity-50' ]"
  >
    <!-- //:class="bg-color" -->
    <div class="container-fluid">
        <a class="navbar-brand px-5 ps-5 py-0" href="/">
          <img src="../../assets/logo.png" height="60" class="ps-3"/>
        </a>
      <!--<a class="navbar-brand" href="/">ideaSky</a>-->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarContent"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleNavbarColor()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end ps-5" id="navbarContent">
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item pe-3">
            <a id="home" class="nav-link text-white" aria-current="page" href="/">
              <font-awesome-icon icon="home" class="mr-1" />
              Home
            </a>
          </li>
          <li class="nav-item pe-3">
            <a id="news" class="nav-link text-white" href="/news">
              <font-awesome-icon icon="newspaper" class="mr-1" />
              News
            </a>
          </li>
          <li class="nav-item pe-3">
            <a id="member" class="nav-link text-white" href="/member">
              <font-awesome-icon icon="users" class="mr-1" />
              Member
            </a>
          </li>
          <li class="nav-item pe-3">
            <a id="works" class="nav-link text-white" href="/works">
              <font-awesome-icon icon="book" class="mr-1" />
              Works
            </a>
          </li>
          <!-- <li class="nav-item">
            <a id="project" class="nav-link" href="/project">
              <font-awesome-icon icon="folder" class="mr-1" />
              Project
            </a>
          </li> -->
          <li class="nav-item pe-3">
            <a id="facilities" class="nav-link text-white" href="/facilities">
              <font-awesome-icon icon="cogs" class="mr-1" />
              Facilities
            </a>
          </li>
          <li class="nav-item pe-3">
            <a id="aerobox-services" class="nav-link text-white" href="https://legacy.ideasky.app">
              <font-awesome-icon icon="sign-in-alt" class="mr-1" />
              Aerobox Services
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script setup>
import { ref, reactive, onMounted, watch, watchEffect } from 'vue';
import 'bootstrap';

const top = ref(0);
const collapseIsActive = ref(true);
const btn = ref(true);
function toggleNavbarColor() {
  btn.value = !btn.value;
  if (top.value <= 70) {
    collapseIsActive.value = true;
  } else if (btn.value === true) {
    collapseIsActive.value = true;
  }
}
watch(top, (curVal, preVal) => {
  if (curVal < 70) {
    collapseIsActive.value = true;
  } else {
    collapseIsActive.value = false;
  }
});
// 讓選中的Navbar變色
// for feature test
onMounted(() => {
  window.addEventListener('scroll', () => {
    top.value = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
  });
  const location = window.location.href;
  const title = location.substring(location.lastIndexOf('/') + 1);
  if (title) {
    const id = document.querySelector(`#${title}`);
    id.classList.add('active');
  } else {
    const index = document.querySelector('#home');
    index.classList.add('active');
  }
});
</script>
<style scoped>
#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
#navbarContent li {
  font-size: 20px;
}
</style>
