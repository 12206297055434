import { createRouter, createWebHistory } from 'vue-router';
import Index from '../views/Index.vue';
import Member from '../views/Member.vue';
import Works from '../views/Works.vue';
import Facilities from '../views/Facilities.vue';
// import Project from '../views/Project.vue';
import News from '../views/News.vue';

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/news',
    name: 'Newss',
    component: News,
  },
  {
    path: '/news/:id',
    name: 'News',
    component: News,
  },
  {
    path: '/member',
    name: 'Member',
    component: Member,
  },
  {
    path: '/works',
    name: 'Works',
    component: Works,
  },
  {
    path: '/works/:id',
    name: 'Workss',
    component: Works,
  },
  {
    path: '/facilities',
    name: 'Facilities',
    component: Facilities,
  }
  // {
  //   path: '/project',
  //   name: 'Project',
  //   component: Project,
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'ideaSky';

  next();
});

export default router;
