<template>
    <div class="container-fluid">
      <div class="container">
        <h2 class="bg-newblue text-center text-white title d-inline-block rounded-pill p-2"
        :style="{ 'min-width': '10rem' }">合作對象</h2>
        <br>
        <br>
        <div class="d-flex align-content-between flex-wrap">
          <div class="p-2" v-for="cooperation in data" :key=cooperation>
            <img :src="cooperation.photo" class="photo-fit logo" alt="test">
            <!--
            <div class="p-3">
              <p class="p-2">{{ cooperation.name }}</p>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { ideaSkyGetRequest } from '../../composition/api';

const url = `${process.env.VUE_APP_API_URL}cooperation/`;
const data = ref([]);

ideaSkyGetRequest(url).then((cooperationData) => {
  data.value = cooperationData;
  // console.log(data.value);
});
</script>

<style>
.wrapper {
  background: tomato;
  display: flex;
  flex-flow: row wrap;
}
.logo {
  height: 8rem;
}
</style>
