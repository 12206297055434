<template>
  <footer id="footer" class="h-10 footer bg-newblue py-1;">
    <div class="container">
      <div class="d-flex justify-content-center justify-content-md-between">
        <div class="align-self-end">
          <div class="my-4">
            <h3
              class="
                d-inline-block
                fw-bolder
                text-white
                border-bottom border-white border-4
                pb-1
                mb-3 mb-md-3
              "
            >
              CONTACT US
            </h3>
            <br /><br />
            <p class="text-white pb-0 ml-1">地址 : {{ contactData[0]?.address }}</p>
            <p class="text-white pb-0 ml-1">電話 : {{ contactData[0]?.phone }}</p>
            <p class="text-white pb-0 ml-1">信箱 : {{ contactData[0]?.email }}</p>
          </div>
          <!-- 好像沒必要的icon
          <div class="d-flex justify-content-between justify-content-md-start py-md-3">
            <a href="#" class="icon-white pr-3"
              ><font-awesome-icon :icon="['fas', 'envelope']" class="font-awesome-icon" size="3x"
            /></a>
            <a href="#" class="icon-hover icon-white pr-3"
              ><font-awesome-icon
                :icon="['fab', 'facebook-square']"
                class="font-awesome-icon"
                size="3x"
            /></a>
            <a href="#" class="icon-hover icon-white pr-3"
              ><font-awesome-icon
                :icon="['fab', 'telegram-plane']"
                class="font-awesome-icon"
                size="3x"
            /></a>
          </div>
          -->
        </div>

        <!-- <div
          class="slogan text-white d-none d-md-block mt-4"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        > -->
        <div
          class="text-white d-none d-md-block mt-4"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <p>
            {{ contactData[0]?.slogan }}
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import AOS from 'aos';
import { ref } from 'vue';
import { ideaSkyGetRequest } from '../../composition/api';

export default {
  setup() {
    AOS.init();
    const contactData = ref([]);
    const url = 'https://ideasky.app/api/v1/ideasky/contact/';
    ideaSkyGetRequest(url).then((Data) => {
      // console.log(Data);
      contactData.value = Data;
      // console.log(contactData);
    });
    return {
      contactData,
    };
  },
};
</script>
