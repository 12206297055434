import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookSquare, faInstagram, faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import {
  faThumbtack,
  faUsers,
  faFolder,
  faBook,
  faCogs,
  faSignInAlt,
  faHome,
  faEnvelope,
  faPeopleCarry,
  faPlusCircle,
  faCaretDown,
  faShare,
  faGlobe,
  faNewspaper
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import VueFullPage from 'vue-fullpage.js';
import App from './App.vue';
import router from './router';
// import 'fullpage.js/vendors/scrolloverflow'; // Optional. When using scrollOverflow:true
// // import './fullpage.scrollHorizontally.min'; // Optional. When using fullpage extensions

import '@/assets/scss/all.scss';

library.add(
  faThumbtack,
  faBook,
  faUsers,
  faFolder,
  faGlobe,
  faCogs,
  faSignInAlt,
  faFacebookSquare,
  faInstagram,
  faTelegramPlane,
  faHome,
  faPeopleCarry,
  faPlusCircle,
  faCaretDown,
  faShare,
  faEnvelope,
  faNewspaper
);
function isMobileDeviceFn() {
  const mobileDevice = [
    'Android',
    'webOS',
    'iPhone',
    'iPad',
    'iPod',
    'BlackBerry',
    'Windows Phone',
  ];
  const isMobileDevice = mobileDevice.some((e) => navigator.userAgent.match(e));
  return isMobileDevice;
}

const app = createApp(App);
app.config.globalProperties.$isMobileDevice = isMobileDeviceFn();
app.use(router, VueAxios, axios, AOS); // , VueFullPage TODO: 解決他
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
