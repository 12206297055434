<template>
    <main class="news">
      <div class="container bg-white">
        <br>
        <h2 class="bg-newblue p-2 text-center text-white title d-inline-block rounded-pill "
        :style="{ 'min-width': '10rem' }">最新消息</h2>
        <br>
        <br>
        <swiper
          v-if='swiperInit'
          :breakpoints="swiperOptions.breakpoints"
          navigation
          :pagination="{ clickable: true }"
          autoHeight: true
          class="swiperMain py-3 mx-3 bg-style"
        >
      <swiper-slide
        class="point"
        v-for="item in originData" :key="item.id"
      >
        <a class="nav-link" :href="`/news/${item.id}`">
          <div class="pic">
            <img :src="item.photo?.[0]?.photo" class="photo-fit" />
          </div>
          <div class="card-body bg-white">
            <h5 class="card-title fw-bold pt-2">{{ item.title }}</h5>
            <!--<a :href="item.projectHyperlink" class="markerPen p-1">點我完整說明</a>-->
          </div>
        </a>
      </swiper-slide>
    </swiper>
        <div style="text-align:center">
        <a
          class="
            btn btn-outline-black
            rounded-pill
            border-3
            fw-bolder
            fs-4
            col-2 col-md-2
            py-1
            mt-3
          "
          href="/news"
          role="button"
        >
          More...
        </a>
        </div>
        </div>
    </main>
</template>

<style scoped>
</style>

<script setup>
import { ref } from 'vue';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ideaSkyGetRequest } from '../../composition/api';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const url = `${process.env.VUE_APP_API_URL}news/?is_pinned=true&ordering=-create_date`;
const data = ref([]);
const id = ref();
const swiperOptions = {
  breakpoints: {
    320: { slidesPerView: 1, spaceBetween: 10, slidesPerGroup: 1, height: 250 },
    992: { slidesPerView: 3, spaceBetween: 30, slidesPerGroup: 3 },
  }
};

const originData = ref([]);

const swiperInit = ref(false);
ideaSkyGetRequest(url).then((newsData) => {
  originData.value = newsData.results;
  swiperInit.value = true;
});

</script>
<style scoped>
  .pic {
    overflow: hidden;
  }
  .point:hover img {
    transform:scale(1.1,1.1);
  }
  img {
    transform:scale(1,1);
    transition: all 1s ease-out;
  }
  .photo-fit {
    object-fit: cover;
    width: 100%;
    height: 300px;
  }
</style>
