<template>
<main class="newdetail">
    <div class="container">
        <a class="link pl-2 ml-2" href="#" @click.prevent="back()">
            <span aria-hidden="true">   &laquo;</span>
            <span aria-hidden="true"> Back</span>
        </a>
        <div class="bg-lightblue rounded row m-5 px-2">
            <div class="col-12 col-md-8">
                <div>
                    <h4 class="pt-4">{{ data.title }}</h4>
                </div>
                <div class="text-end">
                    <span class="text-black-50">
                        發布日期：{{ data.create_date }}
                        更新日期：{{ data.update_date }}
                        <br>
                        點閱率 {{ data.clicked_count }}
                    </span>
                </div>
                <div class="mt-3">
                    <p>{{ data.description }}</p>
                </div>
                <div v-if="data.file.length !== 0" class="py-3 attached">
                  <p>檔案下載：</p>
                  <a v-for="(files, i) in data.file" :key=i target="_blank" :href="files.file">
                    <i class="bi bi-download pe-2"></i>
                    {{ decodeURI(files.file.slice(40, )) }}
                  <br>
                  </a>
                </div>
            </div>
            <div class="col-12 col-md-4 d-flex align-content-end detail">
              <div v-if="data.photo.length === 1" class="align-self-center py-4">
                    <img :src="data.photo?.[0]?.photo" class="img-fluid d-block w-100" />
                </div>
                <div v-else id="carouselExampleIndicators"
                    class="carousel slide py-4 pt-6 align-self-center"
                    data-bs-ride="carousel">
                    <div class="carousel-inner m-1">
                        <div v-for="(i , index) in data.photo" :key="index"
                        class="carousel-item" :class="index === 0 ? 'active':''">
                        <img :src="i.photo" class="img-fluid d-block w-100">
                        </div>
                    </div>
                    <div class="carousel-indicators pt-2">
                        <button v-for="(i , index) in data.photo" :key="index" type="button"
                        data-bs-target="#carouselExampleIndicators"
                        :data-bs-slide-to='index-0' class="img-thumbnail" aria-current="true"
                        :class="index === 0 ? 'active':''" :aria-label="`Slide ${index}`">
                        <img :src="i.photo" class="img-fluid d-block w-100">
                        </button>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</main>
</template>
<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { ideaSkyGetRequest } from '../composition/api';
import 'bootstrap/dist/css/bootstrap.min.css';

const props = defineProps({ data: Object });
const emit = defineEmits(['backnews']);
console.log(props.data);
const puturl = `${process.env.VUE_APP_API_URL}news/${props.data.id}/`;
console.log(puturl);
fetch(puturl, {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
  },
})
  .then((response) => response.json())
  .then((dataa) => {
    console.log('Success:', dataa);
  })
  .catch((error) => {
    console.error('Error:', error);
  });

function back() {
  emit('backnews');
}

</script>

<style type="css">
 .detail.carousel-indicators button.thumbnail:not(.active){
  opacity: 0.8;
}
</style>
