<template>
  <main class="Works">
    <div class="container mb-5 pt-4">
      <h2 class="text-newblue fw-bold pb-2 border-bottom border-5 border-newblue">Works</h2>
      <!-- sticky-top bar -->
      <div class="border-top border-3 mb-4 sticky-top">
        <button
          class="btn btn-light btn-show fw-bolder mr-2"
          v-for="(year, index) in years.slice()"
          :key="year"
          :class="{ 'btn-hidden': scrollY < yearsPositionArray[index] }"
          @click="scrollToYearPosition(index)"
        >
          {{ year }}
        </button>
      </div>
      <!-- works -->
      <section
        v-for="(year, index) in years"
        :ref="
          (el) => {
            yearsRefs[index] = el;
          }
        "
        :key="index"
      >
        <h2 class="markerPen d-inline-block fw-bolder px-1 mb-4">{{ year }}</h2>
        <article
          class="mb-5"
          v-for="(eachWork, worksIndex) in worksData"
          :ref="
            (element) => {
              articleRefs[year + eachWork.id] = element;
            }
          "
          :key="worksIndex"
        >
          <div
            v-if="year == CheckYears(eachWork)"
            class="card row g-0 border border-2"
            :style="{
              background:
                'linear-gradient(280deg, #fff 50%, transparent 50%) center center/ 100% 100%,' +
                'url('+
                eachWork.photo[0].photo +
                ')  left center / cover',
            }"
            :class="{ 'is-flipped': cardID === year + eachWork.id }"
            @click="forCardFlip(year + eachWork.id)"
          >
            <div class="offset-7 col-4 my-5">
              <!-- card__face card__face-front -->
              <section class="mx-auto">
                <h2 class="fw-bold md-3 mb-md-5">
                  {{ eachWork.name }}
                </h2>
                <p>
                  {{ `${eachWork.description.slice(0, wordLimit)}....`
                  }}<span class="fw-light text-muted">(點擊查看更多)</span>
                </p>
              </section>
            </div>
            <!-- 翻面後 -->
            <div
              class="
                card__face card__face--back
                text-dark
                d-flex
                justice-content-start
                align-items-end
                p-3
              "
              :style="{
                background:
                  'linear-gradient(80deg, #fff 15%, transparent 50%) center center/ 100% 100%,' +
                  'url(' +
                  eachWork.photo[0].photo +
                  ')  left center /  cover',
              }"
            >
              <h2 class="d-inline-block fw-bolder text-muted">{{ eachWork.name }}</h2>
            </div>
          </div>
          <transition name="fade">
            <section
              class="row g-0 py-4 position-relative flex-row-reverse"
              v-show="(cardID === year + eachWork.id) & (isActive === true)"
            >
              <h3 class="d-block text-muted fw-bolder">作品介紹</h3>
              <div class="row d-flex">
               <div class="offset-1 col-md-7 col-12 align-content-start">
                  <p class="py-5">
                    {{ eachWork.description }}
                  </p>
                  <p class="d-flex text-end text-black-50">
                    {{ eachWork.note }}
                  </p>
                  <div v-if="eachWork.file.length !== 0"
                  class="pb-3 attached facilitydoc align-self-end">
                    <p>檔案下載：</p>
                    <a v-for="(files, i) in eachWork.file" :key=i target="_blank"
                    :href="files.file">
                      <i class="bi bi-download pe-2"></i>
                      {{ decodeURI(files.file.slice(40, )) }}
                    <br>
                    </a>
                </div>
               </div>
               <div class="col-md-4 col-12 mb-2">
                <div v-if="eachWork.photo.length !== 1" id="carouselExampleIndicators"
                  class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
              <button v-for="(i , index) in eachWork.photo" :key="index" type="button"
                data-bs-target="#carouselExampleIndicators"
                :data-bs-slide-to='index' :aria-current="index === 0 ? 'true':''"
                :class="index === 0 ? 'active':''" :aria-label="`Slide ${index}`">
              </button>
            </div>
            <div class="carousel-inner">
              <div v-for="(i , index) in eachWork.photo" :key="index"
              class="carousel-item" data-bs-interval="3000" :class="index === 0 ? 'active':''">
              <img :src="i.photo" class="photo-fit d-block w-100">
              </div>
            </div>
            <button class="carousel-control-prev" type="button"
            data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button"
            data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
            <div v-else class="align-self-center py-4">
                <img :src="eachWork.photo?.[0]?.photo" class="img-fluid d-block w-100" />
            </div>
              <p v-if="eachWork.participant.length === 1"
                class="d-flex text-end pt-2">
                  參與者：{{ eachWork.participant[0].name }}
                </p>
                <p v-if="eachWork.participant.length > 1"
                class="d-flex text-end pt-2">
                  參與者：
                  <span v-for="(eachmember, index) in eachWork.participant" :key="index">
                    {{ eachmember.name }}  &nbsp;</span>
                </p>
               </div>
              </div>
            </section>
          </transition>
        </article>
      </section>
      <!-- fixed-bottom bar -->
      <div class="fixed-bottom-bar container border-bottom border-3 mb-4 fixed-bottom">
        <button
          class="btn btn-light btn-show fw-bolder mr-2"
          v-for="(year, index) in years.slice()"
          :key="year"
          :class="{ 'btn-hidden': scrollY > yearsPositionArray[index] }"
          @click="scrollToYearPosition(index)"
        >
          {{ year }}
        </button>
      </div>
      <!-- 關閉說明 btn -->
      <!-- 太阿雜了 先關掉
      <transition name="fade">
        <div class="container" style="position: fixed;bottom:6%;">
          <button
            class="btn btn-primary col-11 col-md-12 fw-bolder"
            v-if="isActive"
            @click="forBtnCloseCard()"
          >
        btn 功用關閉 isActive ps.當 isActive + ID 都正確 => 才展開說明
            關閉說明
          </button>
        </div>
      </transition>
      -->
    </div>
  </main>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { ref, reactive } from 'vue';
import {
  scrollPositionDetect,
  cardFlip,
  // btnCloseCard,
  yearsPositionArrayProduce,
} from '../composition/works';
import { ideaSkyGetRequest } from '../composition/api';

const participant = ref();
// 存放 Refs 以獲取元素高 => 調轉到指定位置
const yearsRefs = ref([]);
const articleRefs = reactive({});
const wordLimit = ref(80);

// 偵測目前位置決定sticky-top bar出現年份 & 跳轉到年份位置function
const scrollY = scrollPositionDetect().y;
// console.log(scrollY);
const yearsPositionArray = ref([]);

// API 資料串接
const url = `${process.env.VUE_APP_API_URL}data/?mytype=w`;
const worksData = ref([]);
const years = ref([]);

const getAllYearsData = async (yearsByFetch) => {
  const allRequestArray = [];
  yearsByFetch.forEach((eachProfile) => {
    const eachYear = eachProfile.create_date.slice(0, 4);
    allRequestArray.push(eachYear);
  });
  years.value = Array.from(new Set(allRequestArray))
    .sort()
    .reverse();
  // console.log(years);
  setTimeout(() => {
    yearsPositionArray.value = yearsPositionArrayProduce(yearsRefs.value).positionArray;
  }, 100);
};

ideaSkyGetRequest(url).then((yearsData) => {
  worksData.value = yearsData;
  getAllYearsData(yearsData);
});

const scrollToYearPosition = (index) => {
  const top = yearsRefs.value[index].offsetTop;
  window.scrollTo(0, top - 180); // -100px 是不要讓 sticky-top bar 擋住年份
};

// 卡片是否翻轉/關閉
const cardID = ref(null);
const isActive = ref(false);
// 先選定要的 function ，再帶入當前狀況判斷且更新 cardID isActive
const updateState = (fn) => (currentCardId, currentActive) => (id) => {
  const { newID, newActive } = fn(currentCardId, currentActive, id);
  cardID.value = newID;
  isActive.value = newActive;
};

const forCardFlip = (id) => {
  console.log('1', id);
  updateState(cardFlip)(cardID.value, isActive.value)(id);

  // setTimeout 等前面 article 關閉，以免定位錯誤
  setTimeout(() => {
    const top = articleRefs[id].offsetTop;
    window.scrollTo(0, top - 150); // -100px 是不要讓 sticky-top bar 擋住年份
  }, 250); // 在 works.scss 有 .fade-enter-active 設定transition時間目前為0.2ms
};

function CheckYears(data) {
  const WorksYear = data.create_date.slice(0, 4);
  return WorksYear;
}

// 偵測從首頁傳來的router資料
const route = useRoute();
if (route.params.id) {
  const routeid = route.params.id;
  setTimeout(() => {
    forCardFlip(routeid);
  }, 1000);
}
</script>
