<template>
  <div class="content">
    <Navbar style="'backdrop-filter: blur(2px);" />
    <router-view />
  </div>

  <Footer />
</template>
<style>
/* 確保 Footer 固定在底下 */
#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}
</style>
<script>
import Navbar from '@/components/layout/Navbar.vue';
import Footer from '@/components/layout/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
  },
  setup() {},
};
</script>
